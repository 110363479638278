const caseStudyText = [{
    id: "svgMap-map-country-GB",
    text:
        <div>
            <p> Between the announcement of the tax in 2016 and implementation in 2018, 50 per cent of manufacturers reduced the sugar content of their drinks, reportedly removing approximately 45 million kg of sugar from the market every year.4 During this time, the total volume of sugars sold from soft drinks decreased by 29 per cent, whereas the volume sales of bottled water and products exempt from the tax rose by 23 per cent.</p>

            <p>Approximately a year following implementation, the percentage of drinks with enough sugar to be subject to the tax had fallen from an expected 49 per cent to 15 per cent. Additionally, there was an increase in the proportion of drinks with a sugar level between 4.5g and 5g per 100mL, indicating that many manufacturers chose to reformulate to just below the 5g threshold.</p>
        </div>,
    citations:
        <div>
            <hr />
            <p><sup>4</sup> HM Treasury (5 April 2018). "Soft Drinks Industry Levy comes into effect." GOV.UK. Retrieved 04/01/2022 from: https://www.gov.uk/government/news/soft-drinks-industry-levy-comes-into-effect</p>
            <p><sup>5</sup> Bandy, L.K., Scarborough, P., Harrington, R.A., Rayner, M. &#38; Jebb, S.A. (2020). Reductions in sugar sales from soft drinks in the UK from 2015 to 2018. BMC Medicine 18. Doi: 10.1186/s12916019-1477-4 </p>
            <p><sup>6</sup> Scarborough, P., Adhikari, V., Harrington, R.A., Elhussein, A., Briggs, A., Rayner, M. … &#38; White, M. (2020).Impact of the announcement and implementation of the UK Soft Drinks Industry Levy on sugar content, price, product size and number of available soft drinks in the UK, 2015 - 19: A controlled interrupted time series analysis.PLoS Medicine 17(2), e1003025.Doi: 10.1371 / journal.pmed.1003025</p>
            <p><sup>7</sup> Scarborough, P., Adhikari, V., Harrington, R.A., Elhussein, A., Briggs, A., Rayner, M. … &#38; White, M. (2020).Impact of the announcement and implementation of the UK Soft Drinks Industry Levy on sugar content, price, product size and number of available soft drinks in the UK, 2015 - 19: A controlled interrupted time series analysis.PLoS Medicine 17(2), e1003025.Doi: 10.1371 / journal.pmed.1003025</p>
        </div>
}, {
    id: "svgMap-map-country-MX",
    text:
        <div>
            <p> Since the introduction of the tax there has been a reduction in household purchases of taxed beverages (an estimated average of 7.6% in the first two years after introduction). The introduction of the tax has also resulted in individual changes in consumption, with the probability of becoming a non-consumer increasing by 4.7 per cent and the probability of becoming a low consumer increasing by 8.3 per cent following introduction of the tax. It was estimated that there was a 16.2 per cent increase in purchases of still and sparkling bottled water following introduction of the tax, indicating a substitution to water by consumers.</p>
        </div>,
    citations:
        <div>
            <hr />
            <p><sup>1</sup> Colchero, M. A., Rivera-Dommarco, J., Popkin, B. M., &#38; Ng, S. W. (2017). In Mexico, evidence of sustained consumer response two years after implementing a sugar-sweetened beverage tax. Health Affairs, 36(3), 564-571. </p>

            <p><sup>2</sup> Sánchez-Romero, L. M., Canto-Osorio, F., González-Morales, R., Colchero, M. A., Ng, S. W., Ramírez-Palacios, P., ... &#38; Barrientos-Gutiérrez, T. (2020). Association between tax on sugar sweetened beverages and soft drink consumption in adults in Mexico: open cohort longitudinal analysis of Health Workers Cohort Study. bmj, 369. </p>

            <p><sup>3</sup> Colchero, M.A., Molina, M. &#38; Guerrero-Lopez, C.M. (2017). After Mexico Implemented a Tax, Purchases of Sugar-Sweetened Beverages Decreased and Water Increased: Difference by Place of Residence, Household Composition, and Income Level. The Journal of Nutrition 147(8), 1552–1557. Doi: 10.3945/jn.117.251892</p>
        </div>
}, {
    id: "svgMap-map-country-CL",
    text:
        <div>
            <p>One year post-implementation of the tax there was a 21.6 per cent decrease in the monthly purchased volume of soft drinks with more than 6.5g of sugar per 100mL, however the decrease in purchasing behaviour was most prominent in high socioeconomic groups (31.3 per cent) compared to middle and low socioeconomic groups (16.4 and 12.8 per cent respectively). Another study revealed that the combination of the sugar tax and other labelling, marketing and school food sales policies resulted in a 23.7 per cent decrease in purchases of beverages high in sugar, saturated fat, sodium and/or calories.</p>
        </div>,
    citations:
        <div>
            <hr />
            <p><sup>8</sup> Nakamura, R., Mirelman, A. J., Cuadrado, C., Silva-Illanes, N., Dunstan, J., &#38; Suhrcke, M. (2018). Evaluating the 2014 sugar-sweetened beverage tax in Chile: an observational study in urban areas. PLoS medicine, 15(7), e1002596. Doi: 10.1371/journal.pmed.1002596</p>

            <p><sup>9</sup> Taillie, L. S., Reyes, M., Colchero, M. A., Popkin, B., &#38; Corvalán, C. (2020). An evaluation of Chile’s Law of Food Labeling and Advertising on sugar-sweetened beverage purchases from 2015 to 2017: A before-and-after study. PLoS medicine, 17(2), e1003015. Doi: 10.1371/journal.pmed.1003015</p>
        </div>
}, {
    id: "svgMap-map-country-US",
    text:
        <div>
            <p>One year following implementation of Berkely’s SSB tax, sales of taxed SSBs fell by 9.6 per cent, while sale of untaxed beverages rose by 3.5 per cent. One year following implementation of the tax, consumption of SSBs decreased by 21 per cent in Berkeley compared to the comparison cities of Oakland and San Francisco, California without the tax.  Three years following implementation of the tax, there was a 52.3 per cent reduction in consumption frequency of SSBs, and a 25.1 per cent increase in water consumption frequency.</p>
        </div>,
    citations:
        <div>
            <hr />
            <p><sup>10</sup> Silver, L. D., Ng, S. W., Ryan-Ibarra, S., Taillie, L. S., Induni, M., Miles, D. R., ... &#38; Popkin, B. M. (2017). Changes in prices, sales, consumer spending, and beverage consumption one year after a tax on sugar-sweetened beverages in Berkeley, California, US: A before-and-after study. PLoS medicine, 14(4), e1002283. Doi: 10.1371/journal.pmed.1002283</p>

            <p><sup>11</sup> Falbe, J., Thompson, H. R., Becker, C. M., Rojas, N., McCulloch, C. E., &#38; Madsen, K. A. (2016). Impact of the Berkeley excise tax on sugar-sweetened beverage consumption. American journal of public health, 106(10), 1865-1871. Doi: 10.2105/AJPH.2016.303362</p>

            <p><sup>12</sup> Lee, M. M., Falbe, J., Schillinger, D., Basu, S., McCulloch, C. E., &#38; Madsen, K. A. (2019). Sugar-sweetened beverage consumption 3 years after the Berkeley, California, sugar-sweetened beverage tax. American journal of public health, 109(4), 637-639. Doi: 10.2105/AJPH.2019.304971</p>
        </div>
}, {
    id: "svgMap-map-country-ZA",
    text:
        <div>
            <p>One year following implementation of the tax, volume of taxable beverages purchased fell by 28.9 per cent. Lower-income households showed the greatest decrease in consumption, with the volume of taxable beverages purchased decreasing by 31.6 per cent. These reductions were due to both sugar reduction in the formulation of SSBs by manufacturers, as well as volume reductions by consumers.  </p>
        </div>,
    citations:
        <div>
            <hr />
            <p><sup>13</sup> Stacey, N., Edoka, I., Hofman, K., Swart, E. C., Popkin, B., &#38; Ng, S. W. (2021). Changes in beverage purchases following the announcement and implementation of South Africa's Health Promotion Levy: an observational study. The Lancet Planetary Health, 5(4), e200-e208. Doi: 10.1016/S2542-5196(20)30304-1</p>

            <p><sup>14</sup> Essman, M., Taillie, L. S., Frank, T., Ng, S. W., Popkin, B. M., &#38; Swart, E. C. (2021). Taxed and untaxed beverage intake by South African young adults after a national sugar-sweetened beverage tax: A before-and-after study. PLoS medicine, 18(5), e1003574. Doi: 10.1371/journal.pmed.1003574</p>
        </div>
}
]
export default caseStudyText