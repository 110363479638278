import { useEffect, useState } from 'react';
import svgMap from 'svgmap';
import 'svgmap/dist/svgMap.min.css';
import Modal from 'react-modal/lib/components/Modal';
import caseStudies from './data/case-studies.json'
import caseStudiesText from './data/case-study-long-text'

function App() {

  const studies = caseStudies.map(study => study)

  Modal.setAppElement('#jurisdiction-map');

  const [modalIsOpen, setIsOpen] = useState(false)
  const [caseStudy, setCaseStudy] = useState()

  const legendStyles = {
    display: 'flex',
    position: "absolute",
    zIndex: '10',
    right: '16px',
    bottom: '16px',
    padding: '8px',
    background: 'white',
    border: '5px solid'
  }

  const styles = {
    content: {
      top: '50%',
      left: '50%',
      maxWidth: '600px',
      maxHeight: '700px',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      zIndex: '4',
      transform: 'translate(-50%, -50%)',
      outline: '5px solid black',
      borderRadius: '0px',
      display: 'flex',
      flexDirection: 'column'
    },
  };

  const openModal = (id) => {
    setIsOpen(true)
    const longText = caseStudiesText.filter(study => study.id === id)
    const countryStudy = studies.filter(study => study.id === id)
    setCaseStudy(countryStudy.map(study => {
      return (
        <>
          <div className='d-flex w-100 justify-content-between'>
          <div className='modal-header'><h2>{study.country}</h2></div>
            <button className='btn' onClick={closeModal}><i class="fak fa-xmark"></i> Close</button>
          </div>
          <div className='my-3'><strong>Implemented</strong><p>{study.implemented}</p></div>
          <div className='my-3'><strong>Target</strong><p>{study.target}</p></div>
          <div className='my-3'><strong>Tax type</strong><p>{study.taxType}</p></div>
          <div className='my-3'><strong>Outcome</strong></div>{longText[0].text}
        </>
      )
    }))
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    new svgMap({
      targetElementID: 'svgMap',
      noDataText: "Does not have a sugar tax",
      colorMax: '#79DDB6',
      data: {
        data: {
          sugarTax: {
            name: '',
          },
        },
        applyData: 'sugarTax',
        values: {
          AS: { sugarTax: "Has a sugar tax" },
          BH: { sugarTax: "Has a sugar tax" },
          BB: { sugarTax: "Has a sugar tax" },
          BE: { sugarTax: "Has a sugar tax" },
          BM: { sugarTax: "Has a sugar tax" },
          BN: { sugarTax: "Has a sugar tax" },
          CL: { sugarTax: "<div class='text-center'>Has a sugar tax<br><strong>Select to learn more</strong></div>"},
          CK: { sugarTax: "Has a sugar tax" },
          DM: { sugarTax: "Has a sugar tax" },
          EC: { sugarTax: "Has a sugar tax" },
          EE: { sugarTax: "Has a sugar tax" },
          FJ: { sugarTax: "Has a sugar tax" },
          FI: { sugarTax: "Has a sugar tax" },
          FR: { sugarTax: "Has a sugar tax" },
          PF: { sugarTax: "Has a sugar tax" },
          GU: { sugarTax: "Has a sugar tax" },
          HU: { sugarTax: "Has a sugar tax" },
          IN: { sugarTax: "Has a sugar tax" },
          IE: { sugarTax: "Has a sugar tax" },
          KI: { sugarTax: "Has a sugar tax" },
          LV: { sugarTax: "Has a sugar tax" },
          MY: { sugarTax: "Has a sugar tax" },
          MV: { sugarTax: "Has a sugar tax" },
          MH: { sugarTax: "Has a sugar tax" },
          MU: { sugarTax: "Has a sugar tax" },
          MX: { sugarTax: "<div class='text-center'>Has a sugar tax<br><strong>Select to learn more</strong></div>"},
          MA: { sugarTax: "Has a sugar tax" },
          NR: { sugarTax: "Has a sugar tax" },
          NC: { sugarTax: "Has a sugar tax" },
          NU: { sugarTax: "Has a sugar tax" },
          MP: { sugarTax: "Has a sugar tax" },
          NO: { sugarTax: "Has a sugar tax" },
          OM: { sugarTax: "Has a sugar tax" },
          PA: { sugarTax: "Has a sugar tax" },
          PE: { sugarTax: "Has a sugar tax" },
          PH: { sugarTax: "Has a sugar tax" },
          PL: { sugarTax: "Has a sugar tax" },
          PT: { sugarTax: "Has a sugar tax" },
          QA: { sugarTax: "Has a sugar tax" },
          WS: { sugarTax: "Has a sugar tax" },
          SA: { sugarTax: "Has a sugar tax" },
          SC: { sugarTax: "Has a sugar tax" },
          ZA: { sugarTax: "<div class='text-center'>Has a sugar tax<br><strong>Select to learn more</strong></div>"},
          ES: { sugarTax: "Has a sugar tax" },
          LK: { sugarTax: "Has a sugar tax" },
          SH: { sugarTax: "Has a sugar tax" },
          TH: { sugarTax: "Has a sugar tax" },
          TO: { sugarTax: "Has a sugar tax" },
          TV: { sugarTax: "Has a sugar tax" },
          AE: { sugarTax: "Has a sugar tax" },
          GB: { sugarTax: "<div class='text-center'>Has a sugar tax<br><strong>Select to learn more</strong></div>"},
          VU: { sugarTax: "Has a sugar tax" },
          WF: { sugarTax: "Has a sugar tax" },
          US: { sugarTax: "<div class='text-center'>Has a sugar tax in specific cities and areas<br><strong>Select to learn more</strong></div>"},
        }
      }
    });
    document.getElementById("svgMap-map-country-GB").addEventListener("click", () => openModal("svgMap-map-country-GB"))
    document.getElementById("svgMap-map-country-MX").addEventListener("click", () => openModal("svgMap-map-country-MX"))
    document.getElementById("svgMap-map-country-CL").addEventListener("click", () => openModal("svgMap-map-country-CL"))
    document.getElementById("svgMap-map-country-US").addEventListener("click", () => openModal("svgMap-map-country-US"))
    document.getElementById("svgMap-map-country-ZA").addEventListener("click", () => openModal("svgMap-map-country-ZA"))
  }, [])

  return (
    <div className="App">
      <div id="svgMap">
      <div style={legendStyles}>
        <p className='mb-0 d-flex items-center' style={{marginRight: "16px"}}><div className='circle' style={{background: "#79DDB6"}}></div> Has a sugar tax</p>
      </div>
      </div>
      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={styles}
      >
        {caseStudy}
      </Modal>
    </div>
  );
}

export default App;
